import React from "react";

import DefaultLayout from "../../../layouts/default";

const DocB = () => (
  <DefaultLayout title="WiCare|Doc-B">
    <div className="white wicare">
      <div className="navContentSpacerHelper"></div>
      <header className="navWrapper">
        <nav className="mainNav">
          <div className="mainNavBackgroundHelper"></div>
          <div className="logo">
            <a href="/">
              <img
                src="/assets/images/logos/wigasoft-logo.svg"
                alt="WigaSoft Logo"
                width="175"
              />
            </a>
          </div>
          <div className="mainNav-opener"></div>
          <ul className="mainLinks">
            <li>
              <a href="/news/">News</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/heime/">Heime</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/spitaeler/">Spitäler</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/rehabilitation/">
                Rehabilitation
              </a>
            </li>
            <li>
              <a href="/portrait/">Über uns</a>
            </li>
            <li>
              <a href="/support/">Support</a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/wigasoft-ag">
                <div className="linkedInIcon" />
              </a>
            </li>
          </ul>
        </nav>

        <nav className="subNav2">
          <div className="subNav2Product">
            <div className="productImage">
              <img
                src="/assets/images/logos/WCN-Doc.svg"
                alt="WiCare|Doc Logo"
                width="40"
              />
            </div>
            <div className="productText">
              <span className="line">WiCare|Doc-B</span>
              <span className="line">
                <span className="is-visible-desktop">
                  &nbsp;für Soziale Einrichtungen
                </span>
              </span>
              {/* <span className="line">
                <span className="is-visible-desktop">
                  &nbsp;Bewohnerdokumentation
                </span>
                &nbsp;für Soziale Einrichtungen
              </span> */}
            </div>
          </div>
          <div className="subNav2-opener"></div>
          <ul className="subNav2Links">
            <li className="is-active">
              <a href="/dokumentationsloesungen/wicare-doc-b/">Übersicht</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/wicare-doc-b/mobile/">Mobile</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/wicare-doc-b/technik/">
                Technik
              </a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/wicare-doc-b/referenzen/">
                Referenzen
              </a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/wicare-doc-b/downloads/">
                Downloads
              </a>
            </li>
          </ul>
        </nav>
      </header>

      <div className="menuBackground">
        <section className="mainBanner">
          <div className="wrapper has-padding-top has-padding-bottom">
            <div className="bannerFlex">
              <div>
                <img
                  className="logo-product"
                  src="/assets/images/logos/wicaredoc-weiss.svg"
                />
              </div>
              <div className="bannerPadding">
                <h1>WiCare|Doc-B</h1>
                <h3 className="padding-17-bottom">
                  Be&shy;woh&shy;ner&shy;do&shy;ku&shy;men&shy;ta&shy;tion{" "}
                  <br />
                  für Soziale Einrichtungen
                </h3>
                <p className="hideMobile">
                  WiCare|Doc-B ist die interprofessionelle und umfassende
                  Bewohnerdokumentation für die Betreuung von Menschen mit
                  Beeinträchtigung.
                </p>
              </div>
            </div>
          </div>
          <div className="background">
            <img
              className="js-img-size-from-parent"
              src="https://wigasoft-prod.imgix.net/backgrounds/bkg-wcd.png?q=50&w=1200&h=400"
            />
          </div>
          <div className="wrapper verySmallStaticPadding-top is-background-color-white">
            <p className="mobileBlue">
              WiCare|Doc ist eine professionelle elektronische Patienten- und
              Bewohnerdokumentation, um eine auf den Menschen ausgerichtete,
              individuelle Pflege und Behandlung zu planen, zu dokumentieren und
              zu beurteilen.
            </p>
          </div>
        </section>

        <div className="wrapper">
          <section className="has-padding-top has-padding-bottom">
            <h1>WiCare|Doc-B</h1>
            <p>
              WiCare|Doc-B ist eine Bewohnerdokumentation für die Betreuung von
              Menschen mit Beeinträchtigung. Die Software enthält verschiedene
              Fachkomponenten und unterstützt somit Ihr interdisziplinäres Team
              während des ganzen Betreuungsprozesses. Beispiele hierfür sind
              Elemente wie die Bedarfserhebung inkl. Massnahmenplanung,
              Zielvereinbarungen und Standortgespräche. Diese können bei Bedarf
              auf Ihre betrieblichen Gegebenheiten angepasst werden.
            </p>
          </section>
        </div>

        <div className="wrapper mobile-no-padding">
          <section className="cContainer has-margin-bottom is-background-color-blue is-color-white">
            <h3 className="smallStaticPadding-bottom">WiCare|Doc-B</h3>
            <ul className="fliesstext2">
              <li>
                ist die interprofessionelle und umfassende Bewohnerdokumentation
                für die Betreuung von Menschen mit Beeinträchtigung
              </li>
              <li>
                bildet den Betreuungsprozess ab, von der Anamnese über die
                Entwicklungsplanung bis hin zur Evaluation
              </li>
              <li>
                enthält Instrumente, welche die Einschätzung des
                Betreuungsbedarfs unterstützen
              </li>
              <li>
                dient als Nachweis für die Bedarfserhebung (bspw. IBB –
                Individueller Betreuungsbedarf)
              </li>
              <li>
                enthält zudem Elemente zur Pflegeleistungsermittlungshilfe
                (BESA/RAI)
              </li>
              <li>
                erlaubt dem Team den Zugriff auf sowohl fachspezifische wie auch
                interdisziplinäre Informationen
              </li>
              <li>
                stellt verschiedenen Berufsgruppen wie Sozialpädagogen,
                Arbeitsagogen, Ärzte, Pflegefachleute, Physio- und
                Ergotherapeuten eine professionelle Bewohnerdokumentation zur
                Verfügung
              </li>
              <li>
                kann individuell gestaltete Berichte wie bspw.
                Verlegungsberichte generieren
              </li>
              <li>
                wird von der Praxis mithilfe von Fachexperten stetig
                weiterentwickelt
              </li>
              <li>
                verfügt über standardisierte Schnittstellen zu den gängigen
                ERP-Systemen
              </li>
              <li>
                ist dank eines integrierten Ausfall-Konzepts jederzeit verfügbar
              </li>
            </ul>
          </section>

          <section className="has-padding-bottom">
            <h3 className="smallStaticPadding-bottom">
              Komponenten von WiCare|Doc-B
            </h3>
            <p className="fliesstext">
              Die Bewohnerdokumentation WiCare|Doc-B besteht aus verschiedenen
              Komponenten - das heisst, Sie bestimmen, welche Bestandteile in
              Ihrem Betrieb zum Einsatz kommen. Kurz gesagt, Sie erhalten mit
              WiCare|Doc-B eine Lösung, die Ihre betriebsspezifischen
              Bedürfnisse berücksichtigt und entsprechend konfiguriert ist.
            </p>
            <div className="accordeonContainer">
              <div className="tab">
                <input id="tab-one" type="checkbox" name="tabs" />
                <label for="tab-one">
                  <h5>Archivierung</h5>
                </label>
                <div className="tab-content">
                  <div className="row">
                    <p className="fliesstext2">
                      Übergabe der relevanten Dokumente in ein Universalarchiv
                      für die Langzeitablage.
                    </p>
                  </div>
                </div>
              </div>
              <div className="tab">
                <input id="tab-two" type="checkbox" name="tabs" />
                <label for="tab-two">
                  <h5>Gruppenmanager</h5>
                </label>
                <div className="tab-content">
                  <div className="row">
                    <p className="fliesstext2">
                      Fokussiert auf die Darstellung der gesamten Abteilung. Die
                      Einträge aller Klienten werden im Überblick dargestellt.
                    </p>
                  </div>
                </div>
              </div>
              <div className="tab">
                <input id="tab-three" type="checkbox" name="tabs" />
                <label for="tab-three">
                  <h5>Bewohnercockpit</h5>
                </label>
                <div className="tab-content">
                  <div className="row">
                    <p className="fliesstext2">
                      Wird nach der Anmeldung als Startseite angezeigt, dient
                      als Informationsbeschaffungsinstrument
                      (Einlese-/Visitenansicht) und enthält alle relevanten
                      Auskünfte pro Klient.
                    </p>
                  </div>
                </div>
              </div>
              <div className="tab">
                <input id="tab-four" type="checkbox" name="tabs" />
                <label for="tab-four">
                  <h5>Bewohnerinformation</h5>
                </label>
                <div className="tab-content">
                  <div className="row">
                    <p className="fliesstext2">
                      Strukturierte Übersicht aller Informationen zum Klienten.
                    </p>
                  </div>
                </div>
              </div>
              <div className="tab">
                <input id="tab-five" type="checkbox" name="tabs" />
                <label for="tab-five">
                  <h5>Biographie</h5>
                </label>
                <div className="tab-content">
                  <div className="row">
                    <p className="fliesstext2">
                      Beinhaltet die Anamnese und das (laufende) Assessment.
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="tab">
                <input id="tab-six" type="checkbox" name="tabs" />
                <label for="tab-six">
                  <h5>Agogische Zielüberprüfung, Massnahmenplan</h5>
                </label>
                <div className="tab-content">
                  <div className="row">
                    <p className="fliesstext2">Weitere Informationen folgen.</p>
                  </div>
                </div>
              </div> */}
              <div className="tab">
                <input id="tab-seven" type="checkbox" name="tabs" />
                <label for="tab-seven">
                  <h5>Medizinische Diagnosen Arzt und Pflege</h5>
                </label>
                <div className="tab-content">
                  <div className="row">
                    <p className="fliesstext2">
                      Festlegen der ärztlichen Diagnosen.
                    </p>
                  </div>
                </div>
              </div>
              <div className="tab">
                <input id="tab-eight" type="checkbox" name="tabs" />
                <label for="tab-eight">
                  <h5>Verordnung und Bestellwesen</h5>
                </label>
                <div className="tab-content">
                  <div className="row">
                    <p className="fliesstext2">
                      Beinhaltet ärztliche Verordnungen (Medikation,
                      Anordnungen), pflegerische und therapeutische Massnahmen.
                    </p>
                  </div>
                </div>
              </div>
              <div className="tab">
                <input id="tab-nine" type="checkbox" name="tabs" />
                <label for="tab-nine">
                  <h5>Verlaufsberichte/Protokolle</h5>
                </label>
                <div className="tab-content">
                  <div className="row">
                    <p className="fliesstext2">
                      Chronologischer Verlaufsbericht des aktuellen Befindens
                      der Klienten in Freitext, Pflegediagnosen werden
                      vorgeschlagen. Protokolle für verschiedene Berufsgruppen.
                    </p>
                  </div>
                </div>
              </div>
              <div className="tab">
                <input id="tab-ten" type="checkbox" name="tabs" />
                <label for="tab-ten">
                  <h5>Pflegeplan</h5>
                </label>
                <div className="tab-content">
                  <div className="row">
                    <p className="fliesstext2">
                      Zur Erreichung der festgelegten Pflegeziele werden die
                      gepanten Intervention bzw. Handlungen zeitlich im
                      Pflegeplan angezeigt.
                    </p>
                  </div>
                </div>
              </div>
              <div className="tab">
                <input id="tab-eleven" type="checkbox" name="tabs" />
                <label for="tab-eleven">
                  <h5>Überwachung</h5>
                </label>
                <div className="tab-content">
                  <div className="row">
                    <p className="fliesstext2">
                      Kurvendarstellung der gängigen Überwachungsdaten. Über 60
                      Erhebungen, Fokus- und Risikoassessments verfügbar.
                      Freiheitsbeschränkende Massnahmen.
                    </p>
                  </div>
                </div>
              </div>
              <div className="tab">
                <input id="tab-twelve" type="checkbox" name="tabs" />
                <label for="tab-twelve">
                  <h5>Wund-/Stomadokumentation</h5>
                </label>
                <div className="tab-content">
                  <div className="row">
                    <p className="fliesstext2">
                      Protokollierung von Wunden und deren Behandlung, inkl.
                      Zuordnung der entsprechenden Bilder.
                    </p>
                  </div>
                </div>
              </div>
              <div className="tab">
                <input id="tab-thirteen" type="checkbox" name="tabs" />
                <label for="tab-thirteen">
                  <h5>Ereignisdokumentation</h5>
                </label>
                <div className="tab-content">
                  <div className="row">
                    <p className="fliesstext2">
                      Zielvereinbarung sowie Standortgespräch Jahresgespräch.
                    </p>
                  </div>
                </div>
              </div>
              <div className="tab">
                <input id="tab-fourteen" type="checkbox" name="tabs" />
                <label for="tab-fourteen">
                  <h5>Dokumentenverwaltung</h5>
                </label>
                <div className="tab-content">
                  <div className="row">
                    <p className="fliesstext2">
                      Verwaltung von eigenen und externen Dokumenten,
                      Dokumentenablage (Patientenverfügung, Berichte).
                    </p>
                  </div>
                </div>
              </div>
              <div className="tab">
                <input id="tab-fifteen" type="checkbox" name="tabs" />
                <label for="tab-fifteen">
                  <h5>Export von Dokumenten</h5>
                </label>
                <div className="tab-content">
                  <div className="row">
                    <p className="fliesstext2">
                      Nach Excel, Word, PDF und weitere Formate.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div className="wrapper">
          <div className="bContainer blue has-margin-bottom">
            <div className="row">
              <div className="column one-third text-centered smallStaticPadding-left smallStaticPadding-right has-padding-top">
                <img src="/assets/images/logos/WS_EPD-Logo_10_2018.png" />
              </div>
              <div className="column two-third">
                <h5>Anbindung elektronisches Patientendossier (EPD)</h5>
                <p>
                  Als Anbieter und Betreiber von Primärsystemen für die
                  Patientendokumentation ist es für uns eine
                  Selbstverständlichkeit, unsere Kunden auf dem Weg in die
                  Zukunft mit eHealth und dem elektronischen Patientendossier
                  (EPD) zu begleiten.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

          <section className="newsletterAnmeldung">
      <div className="wrapper nlPadding-top">
        <a className="btn grey" href="/newsletter">Newsletteranmeldung</a><br /><br /><br />
      </div>
    </section>

      <footer className="mainFooter">
        <div className="wrapper">
          <div className="breadcrumbs has-padding-small-top smallStaticPadding-bottom">
            <ul>
              <li>
                <a href="/">Startseite</a>
              </li>
              <li>
                <a href="/dokumentationsloesungen/wicare-doc-b/">
                  WiCare|Doc-B
                </a>
              </li>
            </ul>
          </div>
          <div className="footerNotAnotherFlexboxButAGrid linklist">
            <div className="flex-item1">
              <ul>
                <li>
                  <a href="/dokumentationsloesungen/heime/">
                    <strong>Heime</strong>
                  </a>
                </li>
                <li>
                  <a href="/dokumentationsloesungen/spitaeler/">
                    <strong>Spitäler</strong>
                  </a>
                </li>
                <li>
                  <a href="/dokumentationsloesungen/rehabilitation/">
                    <strong>Rehabilitation</strong>
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex-item2">
              <ul>
                <li className="partiallyHidden">
                  <a href="/dokumentationsloesungen/wicare-doc-l/">
                    <span className="is-visible-desktop">WiCare|Doc</span>
                    <span className="is-visible-tablet-until-PC">WCD</span>-L
                    BESA
                  </a>
                </li>
                <li className="partiallyHidden">
                  <a href="/dokumentationsloesungen/wicare-doc-l/index_rai">
                    <span className="is-visible-desktop">WiCare|Doc</span>
                    <span className="is-visible-tablet-until-PC">WCD</span>-L
                    RAI-NH
                  </a>
                </li>
                <li className="partiallyHidden">
                  <a href="/dokumentationsloesungen/wicare-doc-b/">
                    <span className="is-visible-desktop">WiCare|Doc</span>
                    <span className="is-visible-tablet-until-PC">WCD</span>-B
                  </a>
                </li>
                <li className="partiallyHidden">
                  <a href="/dokumentationsloesungen/wicare-doc-h/">
                    <span className="is-visible-desktop">WiCare|Doc</span>
                    <span className="is-visible-tablet-until-PC">WCD</span>-H
                  </a>
                </li>
                <li className="partiallyHidden">
                  <a href="/dokumentationsloesungen/wicare-doc-r/">
                    <span className="is-visible-desktop">WiCare|Doc</span>
                    <span className="is-visible-tablet-until-PC">WCD</span>-R
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex-item3">
              <ul>
                <li className="partiallyHidden">
                  <a href="/dokumentationsloesungen/wicare-tacs/">
                    WiCare|now tacs<sup>&#174;</sup>
                  </a>
                </li>
                <li className="partiallyHidden">
                  <a href="/dokumentationsloesungen/wicare-lep/">
                    WiCare|now LEP<sup>&#174;</sup>
                  </a>
                </li>
                <li className="partiallyHidden">
                  <a href="/dokumentationsloesungen/wicare-le/">
                    WiCare|now LE
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex-item4">
              <ul>
                <li>
                  <a href="/portrait/">
                    <strong>Über uns</strong>
                  </a>
                </li>
                <li className="partiallyHidden">
                  <a href="/portrait/lehrlinge/">Lernende</a>
                </li>
                <li className="partiallyHidden">
                  <a href="/portrait/partner/">Partner</a>
                </li>
                <li className="partiallyHidden">
                  <a href="/portrait/mitgliedschaften/">Mitgliedschaften</a>
                </li>
                <li>
                  <a href="/portrait/kontakt/">Kontakt und Anfahrt</a>
                </li>
              </ul>
            </div>
            <div className="flex-item5">
              <ul>
                <li>
                  <a href="/support/">
                    <strong>Support</strong>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row copyright">
          <div className="wrapper">
            <div className="column">
              © 2023 WigaSoft. Alle Rechte vorbehalten.&nbsp;
            </div>
            <div className="column has-padding-left">
              <ul>
                <li>
                  <a href="/datenschutz/">Datenschutzerklärung</a>
                </li>

              <li>
                <a href="/impressum/">Impressum</a>
              </li>
              </ul>
            </div>
            <div className="column align-right">
              <a href="/portrait/kontakt/" className="anfahrt">
                <img
                  id="location-pointer"
                  src="/assets/images/icons/icon-anfahrt.svg"
                />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </DefaultLayout>
);

export default DocB;
